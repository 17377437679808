export default function useFormMembersByFormId(formIds: string[]) {
  return useAsyncData<Record<string, AppFormMember[]>>(
    `form-members-${formIds.join('-')}`,
    async () => {
      try {
        const { data, status_code } = await $fetch('/api/form/members', {
          params: {
            form_ids: formIds,
          },
        })

        if (status_code === 200 && typeof data === 'object') {
          return (data as Record<string, AppFormMember[]>) ?? {}
        } else {
          throw new Error('Could not fetch team members.')
        }
      } catch (error) {
        console.error(error)
      }

      return {}
    },
    {
      default: () => ({}),
      server: false,
    },
  )
}
