<template>
  <div
    :class="{
      absolute: true,
      'top-0': true,
      'left-0': true,
      'right-0': true,
      'bottom-0': true,
      'overflow-hidden': true,
      'overflow-y-scroll': true,
    }"
  >
    <div v-if="viewMode === 'typeSelect'">
      <div
        :class="{
          sticky: true,
          'top-0': true,
          flex: true,
          'flex-col': true,
          'gap-4': true,
          'p-4': true,
          'bg-white/80': true,
          'dark:bg-gray-900/80': true,
          'backdrop-blur-sm': true,
          'z-10': true,
        }"
      >
        <h5>Select field type</h5>
        <u-form-group>
          <u-input
            v-model="search"
            leading-icon="i-heroicons-magnifying-glass"
            placeholder="Search..."
            @keydown.enter="onSearchEnter"
          />
        </u-form-group>
      </div>
      <div v-if="results.length" class="grid grid-cols-2 gap-4 px-4">
        <div
          v-for="t in results"
          :key="t.item"
          tabindex="0"
          :class="{
            'outline-none': true,
            flex: true,
            'flex-col': true,
            'items-center': true,
            'gap-4': true,
            'p-6': true,
            'cursor-pointer': true,
            border: true,
            'border-solid': true,
            'border-gray-300 dark:border-gray-700': activeType !== t.item,
            'border-indigo-500 bg-gray-100 dark:border-indigo-500 dark:bg-gray-800':
              activeType === t.item,
            'hover:bg-gray-100': true,
            'dark:hover:bg-gray-800': true,
            'focus:bg-gray-100': true,
            'dark:focus:bg-gray-800': true,
            'focus:border-indigo-500': true,
            'dark:focus:border-indigo-500': true,
            'focus:text-indigo-500': true,
            'dark:focus:text-indigo-500': true,
            'rounded-md': true,
          }"
          @click="onTypeSelect(t.item)"
          @keydown.enter="onTypeSelect(t.item)"
        >
          <u-icon
            :name="typeIcons[t.item as keyof typeof typeIcons]"
            class="text-2xl"
          />
          <span class="w-full text-center text-sm">{{ t.item }}</span>
        </div>
      </div>
      <div v-else class="grid h-[110px] items-center justify-center gap-4 px-4">
        <p class="text-sm text-gray-500 dark:text-gray-400">
          <template v-if="search.length < 20">
            There are no matches for <q>{{ search }}</q
            >.
          </template>
          <template v-else> There are no matches for your search. </template>
        </p>
      </div>
      <div
        :class="{
          sticky: true,
          'bottom-0': true,
          flex: true,
          'flex-col': true,
          'gap-4': true,
          'p-4': true,
          'bg-white/80': true,
          'dark:bg-gray-900/80': true,
          'backdrop-blur-sm': true,
          'z-10': true,
        }"
      >
        <u-button color="white" block @click="emit('close')"> Cancel </u-button>
        <u-button
          block
          :disabled="activeType.length === 0"
          @click="onTypeSelect(activeType)"
        >
          Next
        </u-button>
      </div>
    </div>
    <u-form
      v-if="form && viewMode === 'editor'"
      ref="formRef"
      class="overflow-hidden overflow-y-scroll"
      :state="form.state"
      :schema="form.schema"
      @submit="onSubmit"
    >
      <div class="flex flex-col gap-4 p-4">
        <u-button
          variant="link"
          class="w-fit px-1 py-0"
          leading-icon="i-heroicons-arrow-left"
          :disabled="field.attrs?.template"
          @click="viewMode = 'typeSelect'"
        >
          Select field type
        </u-button>
        <h5>{{ activeType }} details</h5>
        <dynamic-form-field
          v-model.trim="form.state.name"
          field-key="name"
          :input="form.input"
          :disabled="field.attrs?.template"
        />
        <dynamic-form-field
          v-model.trim="form.state.help"
          field-key="help"
          :input="form.input"
        />
        <dynamic-form-field
          v-model="form.state.required"
          field-key="required"
          :input="form.input"
        />
        <dynamic-form-field
          v-if="form.input.properties.is_freeform_enabled"
          v-model="form.state.is_freeform_enabled"
          field-key="is_freeform_enabled"
          :input="form.input"
        />
        <template v-if="activeType === 'Number' || activeType === 'Date'">
          <dynamic-form-field
            v-model="form.state.minimum"
            field-key="minimum"
            :input="form.input"
          />
          <dynamic-form-field
            v-model="form.state.maximum"
            field-key="maximum"
            :input="form.input"
          />
        </template>
        <dynamic-form-field
          v-if="activeType === 'Number'"
          v-model="form.state.multipleOf"
          field-key="multipleOf"
          :input="form.input"
        />
        <dynamic-form-field
          v-if="
            activeType === 'Select' ||
            activeType === 'Combobox' ||
            activeType === 'Radio'
          "
          v-model="form.state.enum"
          field-key="enum"
          :input="form.input"
        />
      </div>
      <div
        :class="{
          sticky: true,
          'bottom-0': true,
          flex: true,
          'flex-col': true,
          'gap-4': true,
          'p-4': true,
          'bg-white/90': true,
          'dark:bg-gray-900/90': true,
          'z-10': true,
        }"
      >
        <u-button block color="white" @click="emit('close')"> Cancel </u-button>
        <u-button
          type="submit"
          block
          :disabled="
            !form.isValid ||
            (deepEqual(newField[0], props.field) &&
              props.required === newField[1])
          "
        >
          Update
        </u-button>
      </div>
    </u-form>
  </div>
</template>

<script lang="ts" setup>
import {
  type Schema,
  type SchemaField,
  type SchemaNumber,
  type SchemaString,
  type SchemaStringDate,
  useForm,
} from '@tarcltd/form-vue'
import { useFuse } from '@vueuse/integrations/useFuse'
import { deepEqual } from 'fast-equals'

const props = defineProps<{
  field: SchemaField
  invalidLabels: string[]
  required: boolean
}>()
const emit = defineEmits(['close', 'update'])
const formRef = ref<HTMLFormElement | null>(null)
const viewMode = ref<'typeSelect' | 'editor'>('typeSelect')
const search = ref('')
const types = ref([
  'Text',
  'Textarea',
  'Number',
  'Select',
  'Combobox',
  'Date',
  'Checkbox',
  'Radio',
])
const typeIcons = {
  Text: 'i-heroicons-italic',
  Textarea: 'i-heroicons-chat-bubble-bottom-center-text',
  Number: 'i-heroicons-hashtag',
  Select: 'i-heroicons-list-bullet',
  Combobox: 'i-heroicons-queue-list',
  Date: 'i-heroicons-calendar',
  Checkbox: 'i-heroicons-check',
  Radio: 'i-heroicons-check-circle-solid',
}
const { results } = useFuse(search, types, {
  fuseOptions: {
    threshold: 0.1,
  },
  matchAllWhenSearchEmpty: true,
})
const activeType = ref('Text')
const form = ref<ReturnType<typeof useForm>>()
const newField = computed<[SchemaField, boolean]>(() => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const fieldDef: any = {
    type:
      activeType.value === 'Number'
        ? 'number'
        : activeType.value === 'Checkbox'
          ? 'boolean'
          : /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            ('string' as any),
    name:
      form.value?.state?.name ??
      (props.field as SchemaField & { name: string }).name,
    attrs: {
      'field:type': activeType.value,
    },
  }

  if (typeof props.field.attrs?.template === 'boolean') {
    fieldDef.attrs = {
      ...fieldDef.attrs,
      template: props.field.attrs?.template,
    }
  }

  if (form.value?.state?.help?.length) {
    fieldDef.attrs = {
      ...fieldDef.attrs,
      elementFormGroup: {
        ...fieldDef.attrs?.elementFormGroup,
        help: form.value.state.help,
      },
    }
  }

  const minimum =
    form.value?.state?.minimum ??
    (props.field as SchemaNumber | SchemaStringDate).minimum

  if (typeof minimum === 'number') {
    ;(fieldDef as SchemaNumber).minimum = minimum
  }

  const maximum =
    form.value?.state?.maximum ??
    (props.field as SchemaNumber | SchemaStringDate).maximum

  if (typeof maximum === 'number') {
    ;(fieldDef as SchemaNumber).maximum = maximum
  }

  const multipleOf =
    form.value?.state?.multipleOf ?? (props.field as SchemaNumber).multipleOf

  if (typeof multipleOf === 'number') {
    ;(fieldDef as SchemaNumber).multipleOf = multipleOf
  }

  if (
    fieldDef.attrs?.['field:type'] === 'Combobox' &&
    Boolean(form.value?.state?.is_freeform_enabled) &&
    Array.isArray(form.value?.state?.enum)
  ) {
    fieldDef.attrs = {
      ...fieldDef.attrs,
      elementInput: {
        ...fieldDef.attrs?.elementInput,
        options: useDeepClone(form.value?.state?.enum),
      },
    }
  } else if (Array.isArray(form.value?.state?.enum)) {
    ;(fieldDef as SchemaString).enum = useDeepClone(form.value?.state?.enum)
  }

  if (fieldDef.attrs?.['field:type'] === 'Date') {
    ;(fieldDef as unknown as SchemaStringDate).format = 'date'

    if (typeof minimum === 'string') {
      ;(fieldDef as SchemaStringDate).minimum = minimum
    }

    if (typeof maximum === 'string') {
      ;(fieldDef as SchemaStringDate).maximum = maximum
    }

    fieldDef.attrs = {
      ...fieldDef.attrs,
      mask: 'mm/dd/yyyy',
    }
  }

  if (typeof props.field.attrs?.mask === 'string') {
    fieldDef.attrs = {
      ...fieldDef.attrs,
      mask: props.field.attrs?.mask,
    }
  }

  if (typeof props.field.attrs?.order === 'number') {
    fieldDef.attrs = {
      ...fieldDef.attrs,
      order: props.field.attrs?.order,
    }
  }

  return [fieldDef, form.value?.state?.required === true]
})

function onTypeSelect(type: string) {
  activeType.value = type
  viewMode.value = 'editor'
}

function onSearchEnter() {
  if (results.value.length === 1) {
    activeType.value = results.value[0].item
    viewMode.value = 'editor'
  }
}

function onSubmit() {
  if (!form.value) {
    return
  }

  emit('update', {
    old: props.field,
    new: newField.value[0],
    required: newField.value[1],
  })

  emit('close')

  formRef.value?.clear()
}

function setState(state: SchemaField & { name: string }) {
  if (!form.value) {
    return
  }

  if (
    typeof state.name === 'string' &&
    state.name !== 'Unnamed' &&
    form.value.state.name !== state.name
  ) {
    form.value.state.name = state.name
  }

  if (
    typeof state.attrs?.elementFormGroup?.help === 'string' &&
    form.value.state.help !== state.attrs?.elementFormGroup?.help
  ) {
    form.value.state.help = state.attrs?.elementFormGroup?.help
  }

  if (
    typeof (state as SchemaStringDate).minimum === 'string' ||
    (typeof (state as SchemaNumber).minimum === 'number' &&
      form.value.state.minimum !== (state as SchemaNumber).minimum)
  ) {
    form.value.state.minimum = (state as SchemaNumber).minimum
  }

  if (
    typeof (state as SchemaStringDate).maximum === 'string' ||
    (typeof (state as SchemaNumber).maximum === 'number' &&
      form.value.state.maximum !== (state as SchemaNumber).maximum)
  ) {
    form.value.state.maximum = (state as SchemaNumber).maximum
  }

  if (
    typeof (state as SchemaNumber).multipleOf === 'string' ||
    (typeof (state as SchemaNumber).multipleOf === 'number' &&
      form.value.state.multipleOf !== (state as SchemaNumber).multipleOf)
  ) {
    form.value.state.multipleOf = (state as SchemaNumber).multipleOf
  }

  if (
    Array.isArray((state as SchemaString).enum) &&
    !deepEqual(form.value.state.enum, (state as SchemaString).enum)
  ) {
    form.value.state.enum = useDeepClone((state as SchemaString).enum)
  }

  if (
    Array.isArray((state as SchemaString).attrs?.elementInput?.options) &&
    !deepEqual(
      form.value.state.enum,
      (state as SchemaString).attrs?.elementInput?.options,
    )
  ) {
    form.value.state.enum = useDeepClone(
      (state as SchemaString).attrs?.elementInput?.options,
    )
    form.value.state.is_freeform_enabled = true
  }
}

watch(viewMode, () => {
  search.value = ''
  formRef.value?.clear()
})

watch(
  activeType,
  (value) => {
    const newInput: Schema = {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          name: 'Label',
          exclusiveEnum: props.invalidLabels.filter(
            (label) =>
              label !== (props.field as SchemaField & { name: string }).name,
          ),
          attrs: {
            'field:type': 'Text',
          },
        },
        help: {
          type: 'string',
          name: 'Help text',
          attrs: {
            'field:type': 'Text',
          },
        },
        required: {
          type: 'boolean',
          name: 'Required',
          attrs: {
            'field:type': 'Checkbox',
            default: true,
          },
        },
      },
      required: ['name', 'required'],
    }

    if (value === 'Number') {
      newInput.properties.minimum = {
        type: 'number',
        name: 'Minimum',
        attrs: {
          elementFormGroup: {
            inputmode: 'decimal',
          },
        },
      }
      newInput.properties.maximum = {
        type: 'number',
        name: 'Maximum',
        attrs: {
          elementFormGroup: {
            inputmode: 'decimal',
          },
        },
      }
      newInput.properties.multipleOf = {
        type: 'number',
        name: 'Multiple of',
        attrs: {
          elementFormGroup: {
            inputmode: 'decimal',
          },
        },
      }
    } else if (
      value === 'Select' ||
      value === 'Combobox' ||
      value === 'Radio'
    ) {
      newInput.properties.enum = {
        type: 'array',
        name: 'Options',
        items: {
          type: 'string',
          name: 'Option',
          minLength: 1,
        } as SchemaString,
        minItems: 1,
        attrs: {
          'field:type': 'Options',
          default: [''],
        },
      }

      if (value === 'Combobox') {
        newInput.properties.is_freeform_enabled = {
          type: 'boolean',
          name: 'Allow freeform input',
          attrs: {
            'field:type': 'Checkbox',
          },
        }
      }
    } else if (value === 'Date') {
      newInput.properties.minimum = {
        type: 'string',
        format: 'date',
        name: 'Minimum',
        attrs: {
          'field:type': 'Date',
          mask: 'mm/dd/yyyy',
        },
      }
      newInput.properties.maximum = {
        type: 'string',
        format: 'date',
        name: 'Maximum',
        attrs: {
          'field:type': 'Date',
          mask: 'mm/dd/yyyy',
        },
      }
    }

    form.value = useForm(newInput)

    setState(props.field as SchemaField & { name: string })
  },
  { immediate: true },
)

watch(
  () => props.field,
  (value) => {
    if (activeType.value !== value.attrs?.['field:type']) {
      activeType.value = value.attrs?.['field:type']
    }

    setState(value as SchemaField & { name: string })

    if ((value as SchemaField & { name: string }).name === 'Unnamed') {
      viewMode.value = 'typeSelect'
    } else {
      viewMode.value = 'editor'
    }
  },
  { immediate: true, deep: true },
)

watch(
  () => props.required,
  (value) => {
    if (!form.value) {
      return
    }

    form.value.state.required = value
  },
  { immediate: true },
)
</script>
