<template>
  <div class="grid w-full gap-y-4">
    <u-card v-if="sortedFields.length === 0">
      <div class="grid items-center justify-center justify-items-center gap-4">
        <p>Get started by adding a field.</p>
        <u-button variant="link" @click="addField"> Add field </u-button>
      </div>
    </u-card>
    <draggable
      v-if="sortedFields.length"
      v-model="sortedFields"
      group="fields"
      handle=".drag-handle"
      animation="150"
      item-key="name"
      class="flex w-full flex-col gap-4"
      @start="drag = true"
      @end="drag = false"
    >
      <template #item="{ element }">
        <div
          :key="element[0]"
          :class="{
            grid: true,
            'gap-4': true,
            'p-2': true,
            border: true,
            'border-solid': true,
            'border-gray-300': true,
            'dark:border-gray-700': true,
            'bg-white': true,
            'dark:bg-gray-900': true,
            'rounded-md': true,
            'select-none': true,
            'cursor-pointer': true,
          }"
          @click="onFieldEdit(element[1])"
        >
          <div class="grid grid-cols-[auto_1fr] items-center gap-2">
            <div
              class="drag-handle h-fit px-4"
              :style="{
                cursor: 'ns-resize',
              }"
              @click.stop.prevent
            >
              <u-icon name="i-heroicons-equals" />
            </div>
            <div class="pointer-events-none flex flex-col gap-2">
              <dynamic-form-field
                v-model="stubValue"
                :field-key="element[0]"
                :input="internalManifest"
                disabled
              />
              <div class="pointer-events-auto flex gap-4 self-end">
                <u-button
                  variant="link"
                  class="w-fit px-1 py-0"
                  :disabled="!isEditAllowed"
                >
                  Edit
                </u-button>
                <u-button
                  variant="link"
                  class="w-fit px-1 py-0"
                  :disabled="!isEditAllowed"
                  @click.stop.prevent="onFieldRemove(element[0])"
                >
                  Remove
                </u-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </draggable>
    <u-button
      v-if="sortedFields.length"
      color="white"
      class="w-fit"
      size="sm"
      :disabled="
        !isEditAllowed ||
        (internalManifest &&
          (typeof internalManifest.properties !== 'object' ||
            !Array.isArray(internalManifest.required) ||
            Object.keys(internalManifest.properties).some(
              (key) => key === 'Unnamed',
            )))
      "
      @click="addField"
    >
      Add Field
    </u-button>
  </div>
  <u-slideover v-if="activeField" v-model="isFieldEditorActive">
    <dynamic-form-field-editor
      :field="activeField"
      :invalid-labels="
        Object.keys(manifest?.properties ?? {}).concat(
          Object.keys(manifest?.properties ?? {}),
        )
      "
      :required="
        (Array.isArray(manifest?.required) &&
          Boolean(manifest?.required.includes((activeField as any).name))) ||
        (Array.isArray(manifest?.required) &&
          Boolean(manifest?.required.includes((activeField as any).name)))
      "
      @update="onFieldUpdate"
      @close="isFieldEditorActive = false"
    />
  </u-slideover>
</template>

<script lang="ts" setup>
import type { Schema, SchemaField } from '@tarcltd/form-vue'
import { deepEqual } from 'fast-equals'
import draggable from 'vuedraggable'

const props = defineProps<{
  manifest?: Schema
  isEditAllowed?: boolean
}>()
const emit = defineEmits(['update:manifest'])
const isFieldEditorActive = ref(false)
const drag = ref(false)
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const stubValue = ref<any>()
const internalManifest = computed({
  get: () => {
    if (
      props.manifest &&
      typeof props.manifest.properties === 'object' &&
      Array.isArray(props.manifest.required)
    ) {
      return props.manifest as Schema
    } else {
      return {
        type: 'object',
        properties: {},
        required: [],
      } as Schema
    }
  },
  set(value) {
    if (!deepEqual(value, props.manifest)) {
      emit('update:manifest', value)
    }
  },
})
const sortedFields = ref<[string, SchemaField][]>([])
const activeField = ref<SchemaField>()

function addField() {
  if (!internalManifest.value) {
    return
  }

  let maxOrder = -1

  const sortedCustom = Object.entries(internalManifest.value.properties).sort(
    ([_a, a], [_b, b]) => a.attrs?.order - b.attrs?.order,
  )

  for (const [order, [fieldKey]] of sortedCustom.entries()) {
    internalManifest.value.properties[fieldKey].attrs = {
      ...internalManifest.value.properties[fieldKey].attrs,
      order,
    }

    if (order === sortedCustom.length - 1) {
      maxOrder = order
    }
  }

  internalManifest.value.properties.Unnamed = {
    name: 'Unnamed',
    type: 'string',
    attrs: {
      'field:type': 'Text',
      order: maxOrder + 1,
    },
  } satisfies SchemaField

  internalManifest.value.required.push('Unnamed')

  activeField.value = internalManifest.value.properties.Unnamed
  isFieldEditorActive.value = true
}

function onFieldEdit(field: SchemaField) {
  activeField.value = field
  isFieldEditorActive.value = true
}

function onFieldUpdate(params: {
  old: SchemaField & { name: string }
  new: SchemaField & { name: string }
  required: boolean
}) {
  if (!internalManifest.value) {
    return
  }

  delete internalManifest.value?.properties[params.old.name]
  internalManifest.value.required = internalManifest.value.required.filter(
    (fieldName) => fieldName !== params.old.name,
  )

  internalManifest.value.properties[params.new.name] = params.new

  if (
    params.required &&
    !internalManifest.value.required.includes(params.new.name)
  ) {
    internalManifest.value.required.push(params.new.name)
  } else if (
    !params.required &&
    internalManifest.value.required.includes(params.new.name)
  ) {
    internalManifest.value.required = internalManifest.value.required.filter(
      (fieldName) => fieldName !== params.new.name,
    )
  }

  internalManifest.value.properties[params.new.name].attrs = {
    ...internalManifest.value.properties[params.new.name].attrs,
    order:
      typeof params.new.attrs?.order === 'number'
        ? params.new.attrs?.order
        : Object.values(props.manifest?.properties ?? {}).length +
          Object.values(internalManifest.value.properties).filter(
            (field) =>
              (field as SchemaField & { name: string }).name !==
              params.new.name,
          ).length +
          1,
  }

  internalManifest.value.required = internalManifest.value.required.sort(
    (a, b) =>
      internalManifest.value.properties[a].attrs?.order -
      internalManifest.value.properties[b].attrs?.order,
  )
}

function onFieldRemove(fieldKey: string) {
  if (!internalManifest.value) {
    return
  }

  delete internalManifest.value?.properties[fieldKey]

  internalManifest.value.required = internalManifest.value.required.filter(
    (fieldName) => fieldName !== fieldKey,
  )

  const sortedCustom = Object.entries(internalManifest.value.properties).sort(
    ([_a, a], [_b, b]) => a.attrs?.order - b.attrs?.order,
  )

  for (const [order, [fieldKey]] of sortedCustom.entries()) {
    internalManifest.value.properties[fieldKey].attrs = {
      ...internalManifest.value.properties[fieldKey].attrs,
      order,
    }
  }
}

watch(drag, (value) => {
  if (value || !internalManifest.value || !props.isEditAllowed) {
    return
  }

  const newProperties: Record<string, SchemaField> = {}

  for (const [order, [key, field]] of sortedFields.value.entries()) {
    newProperties[key] = {
      ...field,
      attrs: {
        ...field.attrs,
        order,
      },
    }
  }

  internalManifest.value.properties = newProperties

  internalManifest.value.required = internalManifest.value.required.sort(
    (a, b) =>
      internalManifest.value.properties[a].attrs?.order -
      internalManifest.value.properties[b].attrs?.order,
  )
})

watch(
  internalManifest,
  (value) => {
    sortedFields.value = useDynamicFormFieldSort(value)
  },
  { deep: true, immediate: true },
)
</script>
