<template>
  <div class="grid space-y-4">
    <div class="flex justify-between">
      <h2 v-if="form" class="text-xl">Edit form</h2>
      <h1 v-else class="text-2xl">New form</h1>
      <div v-if="isEditAllowed" class="flex gap-2">
        <u-button
          :disabled="
            isProcessing ||
            !isValidAndDifferent ||
            !manifest.properties ||
            !Object.keys(manifest.properties).length
          "
          :loading="isProcessing"
          @click="throttledSubmit"
        >
          Save
        </u-button>
      </div>
    </div>
    <u-form :state="state" class="space-y-4">
      <u-form-group
        name="name"
        label="Descriptive title"
        help="This will be shown at the top of the form."
        :error="touched.has('name') && left.has('name') && errorState.name"
      >
        <u-input
          v-model="state.name"
          autocomplete="off"
          @focus="onFocus('name')"
          @blur="onBlur('name')"
        />
      </u-form-group>
      <u-button
        type="submit"
        :disabled="
          isProcessing ||
          !isValidAndDifferent ||
          !manifest.properties ||
          !Object.keys(manifest.properties).length
        "
        :loading="isProcessing"
        :style="{
          display: 'none',
        }"
        @click="throttledSubmit"
      >
        Save
      </u-button>
    </u-form>
    <dynamic-form-builder :manifest :is-edit-allowed="isEditAllowed" />
  </div>
</template>

<script lang="ts" setup>
import type { Schema } from '@tarcltd/form-vue'
import { deepEqual } from 'fast-equals'

const props = defineProps<{
  team: AppDatabase['public']['Tables']['teams']['Row']
  form?: AppDatabase['public']['Tables']['forms']['Row']
}>()
const user = useSupabaseUser()
const supabase = useSupabaseClient<AppDatabase>()
const toast = useToast()
const { data: formMembers } = useAsyncData(
  async () => {
    if (!props.form) {
      return []
    }

    const { data } = await useFormMembersByFormId([props.form.id])

    return data.value[props.form.id] ?? []
  },
  {
    default: () => [],
  },
)
const isEditAllowed = computed(
  () =>
    !props.form ||
    formMembers.value.some(
      (member) =>
        member.id === user.value?.id &&
        (member.metadata.role === 'owner' || member.metadata.role === 'editor'),
    ),
)
const {
  state,
  isValid,
  isProcessing,
  touched,
  left,
  errorState,
  onFocus,
  onBlur,
} = useForm(
  {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        name: 'Descriptive title',
        minLength: 1,
      },
    },
    required: ['name'],
  },
  {
    defaults: {
      name: props.form?.name,
    },
  },
)
const manifest = ref<Schema>(
  props.form
    ? (useDeepClone(props.form.schema) as Schema)
    : {
        type: 'object',
        properties: {},
        required: [],
      },
)
const isValidAndDifferent = computed(() =>
  isValid.value && props.form
    ? props.form.name !== state.name ||
      !deepEqual(props.form.schema, manifest.value)
    : true,
)
const throttledSubmit = useThrottleFn(submit, 500)

async function submit() {
  try {
    if (!props.team) {
      return
    }

    isProcessing.value = true

    if (props.form) {
      const { error } = await supabase
        .from('forms')
        .update({
          name: state.name,
          schema: manifest.value,
        })
        .eq('id', props.form.id)

      if (error) {
        throw error
      }

      toast.add({
        color: 'green',
        title: 'Form saved!',
      })
    } else {
      const { data } = await $fetch<{
        data: AppDatabase['public']['Tables']['forms']['Row']
      }>('/api/form', {
        method: 'POST',
        body: {
          team_id: props.team.id,
          name: state.name,
          schema: manifest.value,
        },
      })

      if (!data) {
        throw new Error('No form draft returned from create.')
      }

      await navigateTo(`/${props.team.name}/form/${data.id}`)

      toast.add({
        color: 'green',
        title: 'Form created!',
      })
    }

    isProcessing.value = false
  } catch (error) {
    isProcessing.value = false

    console.error(error)

    if (props.form) {
      toast.add({
        color: 'red',
        title: 'Could not save form.',
      })
    } else {
      toast.add({
        color: 'red',
        title: 'Could not create form.',
      })
    }
  }
}
</script>
